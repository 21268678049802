<template>
    <div class="global-content">
        <van-cell title="数据类型" :value="dataType.value" is-link @click="dataType.showPicker = true"/>
        <van-collapse v-model="activeName" accordion>
            <van-collapse-item title="筛选条件" class="filter">
                <van-cell title="按设备编号查" :value="filter.dev.value" is-link @click="filter.dev.showPicker = true"/>
                <van-cell title="按时间段查询" :value="filter.time.value" is-link @click="filter.time.show = true"/>
                <van-row class="text-center">
                    <van-col span="12"><van-button type="info" size="small" @click="resetData">重置</van-button></van-col>
                    <van-col span="12"> <van-button type="info" size="small" @click="filterData">筛选</van-button></van-col>
                </van-row>
            </van-collapse-item>
        </van-collapse>
        <div class="inner">
             <div v-if="!emptyData">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" :immediate-check="false">
                    <div v-if="melvesVesion === 'M2'">
                        <!-- 新版 -->
                            <div class="block" v-for="(item,index) in dataList" :key="index">
                            <div class="flex bottom-line">
                                <div>名称：{{item.devName || '暂无'}}</div>
                                <div>编号：{{item.devCode}}</div>
                            </div>
                            <div v-if="dataType.code === 1">
                                <van-row class="info">
                                    <van-col span="12">空气温度：{{item.airTemp}} ℃</van-col>
                                    <van-col span="12">空气湿度：{{item.airHumi}} %</van-col>
                                </van-row>
                                <van-row class="info">
                                    <van-col span="12">路面温度：{{item.roadbedTemp  === -273 ? `- -` : `${item.roadbedTemp} ℃`}}</van-col>
                                    <van-col span="12">路面-10cm温度：{{item.road10cmTemp  === -273 ? `- -` : `${item.road10cmTemp} ℃`}}</van-col>
                                </van-row>
                                <van-row class="info">
                                    <van-col span="12">路面状态：{{item.roadState == 1 ? '潮湿' : item.roadState == 2 ? '积水' :item.roadState == 3 ? '积雪' : item.roadState == 4 ? '结冰' :'干燥'}}</van-col>
                                    <van-col span="12">路面覆盖厚度：{{item.coverThickness}} mm</van-col>
                                </van-row>
                                <van-row class="info">
                                    <van-col span="12">能见度：{{item.visibility}} m</van-col>
                                    <van-col span="12">大气压：{{item.atmoPressure}} Pa</van-col>
                                </van-row>
                                <van-row class="info">
                                    <van-col span="12">PM10：{{item.pm10}} ug/m³</van-col>
                                    <van-col span="12">PM2.5：{{item.pm2p5}} ug/m³</van-col>
                                </van-row>
                                 <van-row class="info">
                                    <van-col span="12">PM1.0：{{item.pm1p0}} ug/m³</van-col>
                                    <!-- <van-col span="12">光照强度：{{item.illuminance}} lux</van-col> -->
                                </van-row>
                            </div>
                            <!-- <div v-else-if="dataType.code === 2">
                                <div class="info">
                                    <div class="type">告警类型：{{item.typeName}}</div>
                                    <div class="type">告警状态：{{item.status === '0' ? '告警中' : item.status === '1' ? '已确认' :'已处理'}}</div>
                                    <div class="type">告警内容：{{item.content}}</div>
                                </div>
                            </div> -->
                            <div v-else-if="dataType.code === 3">
                                <van-row class="info">
                                    <van-col span="12">芯片温度：{{item.chipTemp}} ℃</van-col>
                                    <van-col span="12">运行时间：{{item.runTime}} s</van-col>
                                </van-row>
                                <van-row class="info">
                                    <van-col span="12">电源电压：{{item.powVolt}} mV</van-col>
                                    <van-col span="12">电池电压：{{item.batVolt}} mV</van-col>
                                </van-row>
                                <!-- <van-row class="info">
                                    <van-col span="12">充电类型：{{item.chargeType === '1' ? '市电' : '太阳能'}}</van-col>
                                    <van-col span="12">充电状态：{{item.chargeStatus === '1' ? '充电中' : '未充电'}}</van-col>
                                </van-row>
                                <van-row class="info">
                                    <van-col span="12">充电电流：{{item.chargeElectricity}} mA</van-col>
                                    <van-col span="12">复位原因：{{item.restorationReason === '1' ? '电源掉电' : item.restorationReason === '2' ? '看门狗' : item.restorationReason === '3' ? '软复位' : item.restorationReason === '4' ? 'NRst' :item.restorationReason === '5' ? 'WWDGRST' : item.restorationReason === '6' ? 'Low Power' : 'OtherRst'}}</van-col>
                                </van-row> -->
                                <van-row class="info">
                                    <van-col span="12">卫星数量：{{item.satelliteTrack}}</van-col>
                                    <van-col span="12">定位标志：{{item.gpsValid}}</van-col>
                                </van-row>
                                <van-row class="info">
                                    <van-col span="12">硬件版本：{{item.softwareVersion}} v</van-col>
                                    <van-col span="12">软件版本：{{item.hardwareVersion}} v</van-col>
                                </van-row>
                            </div>
                            <div class="time top-line">地理位置：{{item.location || '暂未设置地址'}}</div>
                            <div class="time">更新时间：{{dataType.code === 2 ? item.createTime :item.recordTime}}</div>
                        </div>
                    </div>
                    <div v-else>
                        <!-- 旧版 -->
                        <div class="block" v-for="(item,index) in dataList" :key="index">
                            <div class="flex bottom-line">
                                <div>名称：{{item.devName|| '暂无'}}</div>
                                <div>编号：{{item.devCode}}</div>
                            </div>
                            <div v-if="dataType.code === 1">
                                <van-row class="info">
                                    <van-col span="12">空气温度：{{item.airTemperature}} ℃</van-col>
                                    <van-col span="12">空气湿度：{{item.airHumidness}} %</van-col>
                                </van-row>
                                <van-row class="info">
                                    <van-col span="12">光照强度：{{item.photometricQuantity}} lux</van-col>
                                    <van-col span="12">能见度：{{item.visibility}} m</van-col>
                                </van-row>
                                <van-row class="info">
                                    <van-col span="12">PM10：{{item.pm10}} ug/m³</van-col>
                                    <van-col span="12">PM2.5：{{item.pm2p5}} ug/m³</van-col>
                                </van-row>
                                <van-row class="info">
                                    <van-col span="12">积水结冰类型：{{item.waterFreezesType == 1 ? '潮湿' : item.waterFreezesType == 2 ? '积水' :item.waterFreezesType == 3 ? '积雪' : item.waterFreezesType == 4 ? '结冰' :'干燥'}}</van-col>
                                    <van-col span="12">积水结冰厚度：{{item.waterFreezesThickness}} mm</van-col>
                                </van-row>
                                <van-row class="info">
                                    <van-col span="12">路面温度：{{item.roadTemperature === -273 ? `- -` : `${item.roadTemperature} ℃`}}</van-col>
                                    <van-col span="12">大气压：{{item.atmosphericPressure}} Pa</van-col>
                                </van-row>
                                <van-row class="info">
                                    <van-col span="12">大于0.5um颗粒数：{{item.um0p5}}</van-col>
                                    <van-col span="12">大于1.0um颗粒数：{{item.um1p0}}</van-col>
                                </van-row>
                            </div>
                            <!-- <div v-else-if="dataType.code === 2">
                                <div class="info">
                                    <div class="type">告警类型：{{item.type}}</div>
                                    <div class="type">告警状态：{{item.status === '0' ? '告警中' : item.status === '1' ? '已确认' :'已处理'}}</div>
                                    <div class="type">告警内容：{{item.content}}</div>
                                </div>
                            </div> -->
                            <div v-else-if="dataType.code === 3">
                                <van-row class="info">
                                    <van-col span="12">工作温度：{{item.workTemp}} ℃</van-col>
                                    <van-col span="12">信号强度：{{item.rssi}}</van-col>
                                </van-row>
                                <van-row class="info">
                                    <van-col span="12">电源电压：{{item.batteryPressure}} mV</van-col>
                                    <van-col span="12">电池电压：{{item.batteryPressure}} mV</van-col>
                                </van-row>
                                <van-row class="info">
                                    <van-col span="12">充电类型：{{item.chargeType === '1' ? '市电' : '太阳能'}}</van-col>
                                    <van-col span="12">充电状态：{{item.chargeStatus === '1' ? '充电中' : '未充电'}}</van-col>
                                </van-row>
                                <van-row class="info">
                                    <van-col span="12">充电电流：{{item.chargeElectricity}} mA</van-col>
                                    <van-col span="12">复位原因：{{item.restorationReason === '1' ? '电源掉电' : item.restorationReason === '2' ? '看门狗' : item.restorationReason === '3' ? '软复位' : item.restorationReason === '4' ? 'NRst' :item.restorationReason === '5' ? 'WWDGRST' : item.restorationReason === '6' ? 'Low Power' : 'OtherRst'}}</van-col>
                                </van-row>
                                <van-row class="info">
                                    <van-col span="12">运行时间：{{item.runTime}} s</van-col>
                                    <van-col span="12">通信模块：{{item.comminicationVer}}</van-col>
                                </van-row>
                                <van-row class="info">
                                    <van-col span="12">硬件版本：{{item.hwVer}} v</van-col>
                                    <van-col span="12">软件版本：{{item.swVer}} v</van-col>
                                </van-row>
                            </div>
                            <div class="time top-line">地理位置：{{item.location || '暂未设置地址'}}</div>
                            <div class="time">更新时间：{{dataType.code === 2 ? item.createTime :item.recordTime}}</div>
                        </div>
                    </div>
                </van-list>
             </div>
            <div v-else><van-empty description="没有更多数据了" /></div>
        </div>
        <!-- 数据类型 -->
        <van-popup v-model="dataType.showPicker" round position="bottom">
            <van-picker show-toolbar :columns="dataType.columns" @cancel="dataType.showPicker = false" @confirm="dataTypeConfirm" />
        </van-popup>
        <!-- 设备编号 -->
        <van-popup v-model="filter.dev.showPicker" round position="bottom">
            <van-picker show-toolbar :columns="filter.dev.columns" @cancel="filter.dev.showPicker = false" @confirm="devConfirm" />
        </van-popup>
        <!-- 筛选时间段 -->
        <van-calendar ref="time" v-model="filter.time.show" type="range" :min-date="filter.time.minDate" :max-date="filter.time.maxDate" @confirm="timeConfirm" color="#48a9f9"/>
        <tabBar />
    </div>
</template>

<script>
import tabBar from '@/components/rxroad/tabBar.vue'


const Today = new Date();
var Yesterday = new Date();
Yesterday.setTime(Yesterday.getTime()-24*60*60*1000);

export default {
    name:'Warn',
    components: {
        tabBar
    },
    data(){
        return{
            emptyData:false,
            loading:false,
            finished:false,
            pageNum:1,
            pageSize:10,
            dataList:[],
            initTime:[
                `${Yesterday.getFullYear()}-${Yesterday.getMonth() > 8 ? Yesterday.getMonth() + 1 : '0' + (Yesterday.getMonth() + 1)}-${Yesterday.getDate()>9?Yesterday.getDate():'0'+Yesterday.getDate()}`,
                `${Today.getFullYear()}-${Today.getMonth() > 8 ? Today.getMonth() + 1 : '0' + (Today.getMonth() + 1)}-${Today.getDate()>9?Today.getDate():'0'+Today.getDate()}`
            ],
            activeName: '',
            filter:{
                dev:{
                    devCode:'',
                    value:'',
                    showPicker:false,
                    columns:[]
                },
                time:{
                    start:'',
                    end:'',
                    minDate: new Date(2018, 0, 1),
                    maxDate: new Date(),
                    value:'',
                    show:false
                }
            },
            dataType:{
                code:'',
                value:'',
                showPicker:false,
                columns:[
                    {text: '气象数据',val:1},
                    // {text: '告警数据',val:2},
                    {text: '设备状态',val:3}
                ]
            },
            melvesVesion:null
        }
    },
    mounted(){
        this.dataType.code = this.dataType.columns[0].val;
        this.dataType.value = this.dataType.columns[0].text;
        this.projectCode = window.sessionStorage.getItem('projectCode');
        this.melvesVesion = window.sessionStorage.getItem('melvesVesion');
        this.getDataList();
        this.getDevInfo();
    },
    methods:{
        getDataList(){
            if(this.melvesVesion === 'M2'){
                //新版
                const data = {
                    devCode:this.filter.dev.devCode ? this.filter.dev.devCode : void 0,
                    beginTime:this.filter.time.start ? `${this.filter.time.start} 00:00:00` : void 0,
                    endTime:this.filter.time.end ? `${this.filter.time.end} 23:59:59` :void 0,
                    projectCode:this.projectCode
                }
                if(this.dataType.code === 1 || this.dataType.code === 3){
                    this.$api.MELVES.deviceHistoryM2(this.pageNum,this.pageSize,data).then( d =>{
                        if(Array.isArray(d)){
                            this.loading = false;
                            this.dataList = this.dataList.concat(d);
                            this.pageSize > d.length ? this.finished = true : void 0;
                            this.pageNum ++;
                        }
                    })
                }else{
                    this.$api.MELVES.deviceAlarmInfoData(this.pageNum,this.pageSize,data).then( d =>{
                        if(Array.isArray(d)){
                            this.loading = false;
                            this.dataList = this.dataList.concat(d);
                            this.pageSize > d.length ? this.finished = true : void 0;
                            this.pageNum ++;
                        }
                    })
                }
            }else{
                // 旧版
                const data = {
                    devCode:this.filter.dev.devCode ? this.filter.dev.devCode : void 0,
                    beginTime:this.filter.time.start ? `${this.filter.time.start} 00:00:00` : void 0,
                    endTime:this.filter.time.end ? `${this.filter.time.end} 23:59:59` :void 0,
                    projectCode:this.projectCode
                }
                if(this.dataType.code === 1){
                    this.$api.MELVES.roadStatusData(this.pageNum,this.pageSize,data).then( d =>{
                        if(Array.isArray(d)){
                            this.loading = false;
                            this.dataList = this.dataList.concat(d);
                            this.pageSize > d.length ? this.finished = true : void 0;
                            this.pageNum ++;
                        }
                    })
                }else if(this.dataType.code === 2){
                    this.$api.MELVES.deviceAlarmInfoData(this.pageNum,this.pageSize,data).then( d =>{
                        if(Array.isArray(d)){
                            this.loading = false;
                            this.dataList = this.dataList.concat(d);
                            this.pageSize > d.length ? this.finished = true : void 0;
                            this.pageNum ++;
                        }
                    })
                }else if(this.dataType.code === 3){
                    this.$api.MELVES.deviceStatusData(this.pageNum,this.pageSize,data).then( d =>{
                        if(Array.isArray(d)){
                            this.loading = false;
                            this.dataList = this.dataList.concat(d);
                            this.pageSize > d.length ? this.finished = true : void 0;
                            this.pageNum ++;
                        }
                    })
                }
            }
            
        },
        // 获取设备信息
        getDevInfo(){
            this.$api.MELVES.allDevInfo({
                projectCode:this.projectCode
            }).then( d => {
                d.forEach(element => {
                    element.text = element.devName
                });
                this.filter.dev.columns = d
            })
        },
        // 重置
        resetData(){
            this.filter.dev.value = '';
            this.filter.time.value='';
            this.filter.dev.devCode = '';
            this.filter.time.start = '';
            this.filter.time.end = '';
            this.$refs.time.reset();
        },
        // 筛选
        filterData(){
            this.loading = true;
            this.finished = false;
            this.activeName = '';
            this.dataList = [];
            this.pageNum = 1;
            this.getDataList();
        },
        // 筛选设备编号确认事件
        devConfirm(v){
            this.filter.dev.showPicker = false;
            this.filter.dev.value = v.text;
            this.filter.dev.devCode = v.devCode;
        },
        // 筛选数据类型确认事件
        dataTypeConfirm(v){
            this.loading = true;
            this.dataType.code = v.val;
            this.dataType.value = v.text;
            this.dataType.showPicker = false;
            this.dataList = [];
            this.pageNum = 1;
            this.getDataList();
        },
        // 筛选时间确认事件
        timeConfirm(date) {
            const [start, end] = date;
            this.filter.time.show = false;
            this.filter.time.start = `${this.formatDate(start)}`;
            this.filter.time.end = `${this.formatDate(end)}`;
            this.filter.time.value = `${this.filter.time.start} 至 ${this.filter.time.end}`;
        },
        // 格式化日期显示
        formatDate(date) {
            return `${date.getFullYear().toString()}-${this.fillZero(date.getMonth() + 1)}-${this.fillZero(date.getDate())}`;
        },
        // 日期补零
        fillZero(str) {
            let realNum;
            str < 10 ? realNum = '0' + str : realNum = str;
            return realNum;
        },
    }
}
</script>


<style lang="scss" scoped>
.global-content{
    text-align: left;
    .text-center{
        text-align: center;
        button{
            padding: 0 20px;
            margin-top: 5%;
        }
    }
    .van-cell__value{
        text-align: right;
    }
}
.van-empty{margin: 25% 0;}
.inner{
    text-align: left;
    padding-bottom: 22%;
    .block{
        padding: 4%;
        background-color: #fff;
        font-size: .8rem;
        border: 1px solid #ececec;
        margin-top: 2%;
        .bottom-line{padding-bottom: 2%;border-bottom: 1px solid #f0f0f0;}
        .top-line{padding-top: 2%;border-top: 1px solid #f0f0f0;}
        .flex{
            display: flex;
            justify-content:space-between;
            margin: 2% 0;
            font-weight: bold;
        }
        .info div{margin: 2% 0;color: #13a597;}
        .time{color: #656565;margin-top:2%;}
    }
}
</style>